import { Fragment, useEffect, useState } from "react";
import niceSelect from "react-nice-select";
// import ImageView from "../components/ImageView";
// import VideoPopup from "../components/VideoPopup";
import { animation, sidebarClick } from "../utils";
// import ScrollTop from "./ScrollTop";
// import Footer from "./footer/Footer";
// import Header from "./header/Header";
import SideBar from "./header/SideBar";

import footerdata from "../data/footerdata.json";
import headerdata from "../data/headerdata.json";
import headermenudata from "../data/headermenudata.json";

import dynamic from "next/dynamic";
// const niceSelect = dynamic(() => import('react-nice-select'), {ssr: true});
const ImageView = dynamic(() => import('../components/ImageView'), {ssr: true});
const ScrollTop = dynamic(() => import('./ScrollTop'), {ssr: true});
// const SideBar = dynamic(() => import('./header/SideBar'), {ssr: true});


const Header = dynamic(() => import('./header/Header'), {ssr: true});
const VideoPopup = dynamic(() => import('../components/VideoPopup'), {ssr: true});

const Footer = dynamic(() => import('./footer/Footer'), {ssr: true});

const Layout = ({ children, header, footer, singleMenu }) => {

  const [isLayoutLoad, setLayoutLoad] = useState(false)

  useEffect(() => {
    // Initialize a variable for the timeout to clear it later
    let layoutTimeout;
    let animationsTimeout;
  
    const loadLayoutAndAnimations = () => {
      // First timeout for layout load
      layoutTimeout = setTimeout(() => {
        setLayoutLoad(true);
  
        // Second timeout for animations, nested within the first
        animationsTimeout = setTimeout(() => {
          // Assuming niceSelect, animation, and sidebarClick are synchronous functions
          niceSelect();
          animation();
          sidebarClick();
        }, 1000); // 2000 - 1000 = 1000ms after the first timeout
      }, 1000);
    };
  
    loadLayoutAndAnimations();
  
    // Cleanup function to clear timeouts
    return () => {
      clearTimeout(layoutTimeout);
      clearTimeout(animationsTimeout);
    };
  }, [children]); // Dependency array
  

  const headerDataForMenu = headermenudata.menu_items;

  return (
    <Fragment>
      {isLayoutLoad &&
      <>
         <VideoPopup />
      <ImageView /> 
      </>
    }
    
      <div className="page-wrapper">
      {isLayoutLoad &&
        <>
         <Header menuData={headerDataForMenu} header={header} singleMenu={singleMenu} headerData={headerdata} />
        <SideBar /> 
        </>
      }
        {children}
        {isLayoutLoad &&
        <>
     <Footer  menuData={headerDataForMenu} footer={footer} footerData={footerdata} />
        <ScrollTop />
        </>
      }
      </div>
    </Fragment>
  );
};

export default Layout;
